<template>
  <div>
    <v-dialog v-model="shower" scrollable persistent max-width="500px">
        <v-card >
            <v-card-title>รายการ Invoice ที่ต้องการ Exclude</v-card-title>
            <v-divider></v-divider>
             <v-card-text style="height: 300px;">
                 <v-list three-line>
                     <v-list-item v-for="item in dataexcludeinvoice" :key="item.document_id" class="pa-0">
                         <v-list-item-avatar>
                             <v-icon size="40" color="gray"> mdi-file-chart</v-icon>
                         </v-list-item-avatar>
                         <v-list-item-content>
                             <v-list-item-title class="pt-2 pb-2">Invoice No. {{ item.document_id }} </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-alert
                                    dense
                                    text
                                    :type="item.status === 'Waiting' ? 'primary': item.status === 'Process' ? 'amber darken-3' : item.status === 'Success' ? 'green' : 'red'  "
                                >{{ item.result }}</v-alert>
                            </v-list-item-subtitle>
                         </v-list-item-content>
                         <v-list-item-action>
                             <v-progress-circular :color="item.status === 'Waiting' ? 'primary': item.status === 'Process' ? 'amber darken-3' : item.status === 'Success' ? 'green' : 'red' " :size="40"  :rotate="-90" :value="item.progress" >{{ item.progress }}</v-progress-circular>
                         </v-list-item-action>
                     </v-list-item>
                 </v-list>
             </v-card-text>
             <v-divider></v-divider>
             <v-card-actions> <v-spacer></v-spacer> <v-btn :loading="processing" color="success" @click="fn_excludedata">ดำเนินการ</v-btn>  <v-btn outlined color="error" :disabled="processing" @click="fn_closedialog">ปิด</v-btn> </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['show', 'datainvoice', 'type'],
  name: 'excludeinvoicebyapi',
  data: function () {
    return {
      test: '',
      dataexcludeinvoice: [],
      processing: false
    }
  },
  computed: {
    shower: {
      get () {
        if (this.show === true) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dataexcludeinvoice = this.datainvoice
        }
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('closedialog')
        }
      }
    }
  },
  methods: {
    fn_closedialog () {
      const checkSuccess = this.dataexcludeinvoice.findIndex(r => r.status === 'Success')
      if (checkSuccess !== -1) {
        this.$emit('reloaddata')
      }
      this.$emit('closedialog')
    },
    async fn_excludedata () {
      this.processing = true
      for (let i = 0; i < this.dataexcludeinvoice.length; i++) {
        if (this.dataexcludeinvoice[i].status === 'Success') {
          continue
        } else {
          this.dataexcludeinvoice[i].status = 'Process'
          this.dataexcludeinvoice[i].result = 'Exclude Processing'

          try {
            const payload = { document_id: this.dataexcludeinvoice[i].document_id }
            var AccessToken = JSON.parse(localStorage.DHL_Access).data.one_access_token
            var headers = { Authorization: '' + AccessToken }
            const configaxios = {
              headers,
              onUploadProgress: e => {
                if ((e.loaded / e.total) * 100 >= 95) {
                  this.dataexcludeinvoice[i].progress = 95
                } else {
                  this.dataexcludeinvoice[i].progress = parseInt((e.loaded / e.total) * 100)
                }
              }
            }
            const callapi = await this.axios.post(process.env.VUE_APP_SERVICE_API + '/witholdingtax/exclude/updatestatus', payload, configaxios)
            this.dataexcludeinvoice[i].progress = 100
            if (callapi.data.status === 'OK') {
              this.dataexcludeinvoice[i].status = 'Success'
              this.dataexcludeinvoice[i].result = 'Exclude Success'
            } else {
              this.dataexcludeinvoice[i].status = 'Fail'
              this.dataexcludeinvoice[i].result = callapi.data.errorMessage
            }
            console.log(callapi)
          } catch (ex) {
            if (ex.response) {
              console.log('CASE 1')
              console.log(ex.response.data)
              this.dataexcludeinvoice[i].result = ex.response.data.errorMessage || JSON.stringify(ex.response)
              this.dataexcludeinvoice[i].status = 'Fail'
              this.dataexcludeinvoice[i].progress = 100
            } else if (ex.request) {
              this.dataexcludeinvoice[i].result = JSON.stringify(ex.request)
              this.dataexcludeinvoice[i].status = 'Fail'
              this.dataexcludeinvoice[i].progress = 100
              console.log('CASE 2')
            } else if (ex.message) {
              console.log('CASE 3')
              this.dataexcludeinvoice[i].result = JSON.stringify(ex.message)
              this.dataexcludeinvoice[i].status = 'Fail'
              this.dataexcludeinvoice[i].progress = 100
            }
            console.log(ex)
          }
        }
      }
      this.processing = false
    }
  }
}
</script>

<style></style>
